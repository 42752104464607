import React from "react"

import Layout from "../components/layout"
import { Perex } from "@igloonet-web/shared-ui"

const NotFoundPage = (): React.ReactNode => (
  <Layout>
    <Perex>
      <h1>Dyť tu byla!</h1>
      <p>
        Je to polámané a stránka tu není, zkuste menu nahoře, dole, vpravo nebo
        vlevo.
      </p>
    </Perex>
  </Layout>
)

export default NotFoundPage
